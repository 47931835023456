<script>
export default {
    data() {
        return {
            checkpoints: this.$store.state.api.collection('/attendance/checkpoints'),
            newNode: {
                name: ''
            },
            tpl: {
                drawerIsOpen: false
            }
        }
    },

    methods: {
        createCheckpoint() {
            if (this.newNode.name) {
                this.$store.state.api
                    .post('/attendance/checkpoints', this.newNode)
                    .then(createdNode => {
                        this.checkpoints.push(createdNode);
                        this.tpl.drawerIsOpen = false;
                    });
            }
        }
    },

    mounted() {
        this.checkpoints.fetch();
    }
}
</script>

<template>
    <div class="phi-attendance-admin">
        <div class="phi-media"
            v-for="point in checkpoints.items"
            @click="$emit('select', point)"
        >
            <div class="phi-media-body">{{ point.name }}</div>
        </div>

        <phi-drawer :open="tpl.drawerIsOpen">
            <fieldset>
                <phi-input v-model="newNode.name" :label="$t('noun.name')"></phi-input>
            </fieldset>

            <footer>
                <div :class="['phi-media save action', !newNode.name ? 'disabled' : '']" @click="createCheckpoint">
                    <mu-icon class="phi-media-figure" value="save"></mu-icon>
                    <div class="phi-media-body">{{ $t('action.save') }}</div>
                </div>

                <div class="phi-media cancel action" @click="tpl.drawerIsOpen = false">
                    <mu-icon class="phi-media-figure" value="clear"></mu-icon>
                    <div class="phi-media-body">{{ $t('action.cancel') }}</div>
                </div>
            </footer>
        </phi-drawer>

        <phi-drawer :open="!tpl.drawerIsOpen">
            <div class="phi-media" @click="tpl.drawerIsOpen = true">
                <mu-icon class="phi-media-figure" value="add"></mu-icon>
                <div class="phi-media-body">{{ $t('action.addCheckpoint') }}</div>
            </div>
        </phi-drawer>
    </div>
</template>

<style lang="scss" scoped>
footer {
    display: flex;

    .action {
        transition: all .5s ease;
        &:hover {
            background: rgba(0,0,0,.08);
        }
    }

    .save {
        .phi-media-figure {
            color: green;
        }
    }

    .cancel {
        margin-left: 20px;
        .phi-media-figure {
            color: red;
        }
    }
}

fieldset {
    margin: 0;
    padding: 20px 16px;
    border: 0;

    .phi-input {
        display: block;
    }
}

i.phi-media-figure {
    text-align: center;
    align-self: center;
}

.disabled {
    opacity: 0.4;
}
</style>