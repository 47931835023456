<template>
    <phi-page>
        <div slot="toolbar">
            <mu-icon-button @click="$router.go(-1)" icon="arrow_back" />
            <h1>{{ $t("noun.checkpoints") }}</h1>
        </div>

        <div class="phi-card">
            <phi-attendance-admin @select="goTo(arguments[0])" />
        </div>
    </phi-page>
</template>

<script>
import PhiAttendanceAdmin from '../../components/Phi/Attendance/Admin.vue';
export default {
    components: {PhiAttendanceAdmin},

    methods: {
        goTo(checkpoint) {
            this.$router.push({name: 'attendance-checkpoint', params: {nodeId: checkpoint.id}, query:{title: checkpoint.name} })
        }
    }
}
</script>